<template>
  <div class="survey">
    <div class="row">
      <div class="col-lg-6 col-xl-4 order-lg-2 order-xl-1">
        <DueActions :msg="dueActions" title="Påminnelse" v-show="dueActions.length !== 0"></DueActions>

        <v-guard :permissions="['ticket-comment-view']">
          <div class="widget comments">
            <div class="widget-header">
              <div class="widget-header-label">
                <h3>
                  {{ $t('labels.events') }} <info-box :field="'Ärendeformuläret - händelseloggen rubrik'" />
                  <small
                  >{{ actions.length }} {{ $t('labels.events').toLowerCase() }} <span class="d-inline-block ml-3 bl-1 pl-3">{{ totalTimeComments }} {{ $t('labels.minutes-shorten') }}.</span></small
                  >
                </h3>
              </div>
              <v-guard :permissions="['ticket-comment-add']">
                <div class="widget-header-toolbar" v-if="ticket.is_anonymized !== 1">
                  <button type="button" class="btn btn-btn btn-no-outline-white" v-on:click="hideNewComment"
                          v-if="addComment">
                    <i class="fas fa-times"></i>
                  </button>
                  <button
                      v-tooltip="this.$t('labels.add-event')"
                      type="button"
                      class="btn btn-btn btn-no-outline-white"
                      v-on:click="addNewComment"
                      v-if="!addComment"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </v-guard>
            </div>

            <div class="new-comment bb-1 mb-4 pb-4" v-if="addComment">
              <div class="form-group">
                <label for="edit-comment_text">{{ $t('labels.comment') }}</label>
                <textarea-autosize
                    placeholder=""
                    ref="someName"
                    :min-height="30"
                    :max-height="650"
                    class="form-control"
                    v-model="comment.comment"
                    id="edit-comment_text"
                ></textarea-autosize>
              </div>
              <div class="form-row">
                <div class="form-group col-12">
                  <label>{{ $t('labels.forum') }}</label>
                  <v-select
                      :options="parentActions"
                      transition="none"
                      :filterable="false"
                      v-model="comment.parentAction"
                      :clearable="false"
                      :label="this.$label()"
                  >
                    <span slot="no-options">{{ $t('labels.no-options') }}</span>
                  </v-select>
                </div>
                <div class="form-group col-12">
                  <label>{{ $t('labels.event') }}</label>
                  <v-select
                      :options="childActions"
                      transition="none"
                      :filterable="false"
                      v-model="comment.childAction"
                      :clearable="false"
                      :label="this.$label()"
                  >
                    <span slot="no-options">{{ $t('labels.no-options') }}</span>
                  </v-select>
                </div>
                <div class="form-group col-12">
                  <label>{{ $t('labels.time-spent-in-minutes') }}</label>
                  <input type="number" v-model="comment.time_estimate" class="form-control"/>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <v-guard :permissions="['ticket-comment-delete']">
                    <button type="button" @click="deleteComment(comment)" v-if="comment.id !== null"
                            class="btn btn-outline-danger">
                      {{ $t('labels.delete') }}
                    </button>
                  </v-guard>
                </div>
                <div class="col-6 text-right">
                  <button type="button" @click="saveNewComment" class="btn btn-success">{{ $t('labels.save') }}</button>
                </div>
              </div>
            </div>
            <div class="widget-body">
              <div class="empty-placeholder" v-if="actions.length === 0">
                <i class="fas fa-calendar-times"></i>
                {{ $t('labels.no-events-registered') }}.
              </div>
              <div class="row bb-1 pb-4 mb-4" v-for="comment in actions" :key="comment.id">
                <div class="col-12">
                  <div class="row">
                    <div class="col-10 pt-2">
                      <div
                          class="label mr-3 label-xs"
                          :style="{
													color: comment.action.parent_action.color,
													backgroundColor: comment.action.parent_action.color + '1A',
												}"
                          v-if="comment.action && comment.action.parent"
                      >
                        {{ $to(comment.action.parent_action,'label') }}
                      </div>
                      <div
                          class="label label-xs"
                          :style="{ color: comment.action.color, backgroundColor: comment.action.color + '0A' }"
                          v-if="comment.action"
                      >
                        {{ $to(comment.action,'label') }}
                      </div>
                    </div>
                    <div class="col-2 text-right" style="line-height: 42px;" v-if="ticket.is_anonymized == 0">
                      <div class="mt-0">
                        <v-guard :permissions="['ticket-comment-edit']">
                          <button type="button" class="btn btn-white" @click="editComment(comment)"
                                  style="width: 32px;">
                            <i class="text-muted opacity-60 fas fa-ellipsis-v"></i>
                          </button>
                        </v-guard>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="mb-3 mt-2">{{ comment.comment }}</div>
                    <div class="list-item text-muted" v-if="comment.next_action">
                      {{ $t('labels.next-planned-action-days', {action: comment.next_action.label, number: comment.suggested_action_time_days}) }}.
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-6 mb-3 list">
                      <div class="list-item">
                        <label>{{ $t('labels.created-by') }}</label>
                        <span>{{ comment.user.name }}</span>
                      </div>
                    </div>
                    <div class="col-6 list">
                      <div class="list-item">
                        <label>{{ $t('labels.time-spent') }}</label>
                        <span v-if="comment.time_estimate"> {{ comment.time_estimate }} {{ $t('labels.minutes-shorten')}}.</span>
                        <span v-if="!comment.time_estimate"> -</span>
                      </div>
                    </div>
                    <div class="col-6 list">
                      <div class="list-item">
                        <label>{{ $t('labels.created-at') }}</label>
                        <span> {{ comment.created_at_formatted }}</span>
                      </div>
                    </div>
                    <div class="col-6 list">
                      <div class="list-item">
                        <label>{{ $t('labels.updated-at') }}</label>
                        <span> {{ $to(comment,'updated_at_formatted') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-guard>
      </div>
      <div class="col-lg-12 col-xl-5 order-lg-1 order-xl-1">
        <v-guard :permissions="['ticket-contact-view']">
          <div class="widget" v-if="ticket.is_anonymized !== 1">
            <div class="widget-header">
              <div class="widget-header-label">
                <h3>{{ $t('labels.contact-information') }}</h3>
              </div>
              <div class="widget-header-toolbar">
                <a v-tooltip="this.$t('labels.primary-email')" :href="'mailto:' + ticket.person.email"
                   class="btn btn-btn btn-no-outline-white"
                ><i class="far fa-envelope"></i
                ></a>
                <a v-tooltip="this.$t('labels.secondary-email')" :href="'mailto:' + ticket.person.email_two"
                   class="btn btn-btn btn-no-outline-white ml-3"
                ><i class="fas fa-envelope"></i
                ></a>
              </div>
            </div>
            <div class="widget-body" v-if="!isLoading">
              <div class="row mb-4">

                <div class="col-4 mobile">
                  <h6>{{ $t('labels.mobile') }}</h6>
                  {{ ticket.person.mobile }}
                </div>
                <div class="col-4">
                  <h6>{{ $t('labels.phone-home') }}</h6>
                  {{ ticket.person.phone }}

                  <h6 class="mt-4">{{ $t('labels.phone-work') }}</h6>
                  {{ ticket.person.phone_work }}
                </div>
                <div class="col-4">
                  <h6>{{ $t('labels.address') }}</h6>
                  {{ ticket.person.co_address }}<br v-if="ticket.person.co_address !== null"/>
                  {{ ticket.person.streetname }}<br/>
                  {{ ticket.person.postal_code }} {{ ticket.person.postal_area }}<br/>
                  {{ ticket.person.country }}
                </div>
              </div>
            </div>
          </div>
        </v-guard>
        <v-guard :permissions="['ticket-field-information-view']">
          <div class="widget mb-5">
            <div class="widget-header">
              <div class="widget-header-label">
                <h3>
                  {{ $t('labels.notes') }} <info-box :field="'Ärendeformuläret - anteckningar'" />
                </h3>
              </div>
              <v-guard :permissions="['ticket-field-information-edit']">
                <div class="widget-header-toolbar" v-if="ticket.is_anonymized !== 1">
                  <button
                      v-tooltip="this.$t('labels.edit-note')"
                      v-if="mode === 'view'"
                      type="button"
                      class="btn btn-btn btn-no-outline-white"
                      v-on:click="toggleMode"
                  >
                    <i class="fas fa-pen"></i>
                  </button>
                  <button
                      v-tooltip="this.$t('labels.close-note')"
                      v-if="mode === 'edit'"
                      type="button"
                      class="btn btn-success close-edit"
                      v-on:click="saveTicket()"
                  >
                    <i class="fas fa-check"></i>
                  </button>
                </div>
              </v-guard>
            </div>
            <div class="widget-body no-padding" v-if="isLoading">
              <div class="loading">
                <div class="text-big-xl text-big text-muted">
                  <i class="fas fa-circle-notch fa-spin" v-show="isLoading"></i>
                </div>
              </div>
            </div>
            <div class="widget-body" v-if="!isLoading">
              <div class="row">
                <div class="col-12">
                  <div v-if="mode === 'edit'">
                    <textarea-field
                      @toggleMode="toggleMode"
                      placeholder="Skriv något här…"
                      :model="ticket"
                      api="updateTicketField"
                      field="information"
                      label=""
                      >
                    </textarea-field>
                  </div>
                  <span v-if="mode === 'view'" class="pre-space">{{ ticket.information }}</span>
                </div>
              </div>
              <div class="row mt-4 bt-1 pt-4">
                <div class="col-4">
                  <h6>{{ $t('labels.created-by') }}</h6>
                  <span class="fw-500" style="color: #494c57;">{{ ticket.user.name }}</span>
                </div>
                <div class="col-4">
                  <h6>{{ $t('labels.received-via') }}</h6>
                  <span class="fw-500" style="color: #494c57;">{{
                      ticket.ticket_channel !== null ? $to(ticket.ticket_channel,'label') : '-'
                    }}</span>
                </div>
                <div class="col-4">
                  <h6>{{ $t('labels.first-contact') }}</h6>
                  <span class="fw-500" style="color: #494c57;">{{
                      ticket.first_contact !== null ? $to(ticket.first_contact,'label') : '-'
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </v-guard>
        <v-guard :permissions="['ticket-field-securifid-view']">
          <div class="widget mb-5">
            <div class="widget-header">
              <div class="widget-header-label">
                <h3>
                  Säkra Samtal <info-box :field="'Ärendeformuläret - Säkra Samtal'" />
                </h3>
              </div>
            </div>
            <div class="widget-body" v-if="!isLoading">
              <div class="row" v-if="!securifidUser">
                <div class="col-12">
                  <div class="d-flex my-auto subheader pt-0">
                    <div class="subheader-right mt-0 ">
                      <button type="button" class="btn btn-sm btn-light" v-on:click="getUserFromSecurifid()"> <i class="fas fa-user-circle"></i>
                          {{ $t('labels.show-options') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="securifidUser">
                <div class="col-6">
                  <v-guard :permissions="['ticket-field-securifid-view-code-vocally']">
                    <div class="d-flex my-auto subheader pt-0">
                      <div class="subheader-right mt-0 ">
                        <button type="button" class="btn btn-sm btn-light" v-on:click="getCodeFromSecurifid(false), securifidButtonClicked='submit-code-vocally'"> <i class="fas fa-user-circle"></i>
                              {{ $t('labels.submit-code-vocally') }}
                        </button>
                      </div>
                    </div>
                  </v-guard>
                  <v-guard :permissions="['ticket-field-securifid-view-app']" v-if="securifidUser.registered">
                    <div class="d-flex my-auto mt-2 subheader pt-0" v-if="!securifidUser.error">
                      <div class="subheader-right mt-0 ">
                        <button type="button" class="btn btn-sm btn-light" v-on:click="getCodeFromSecurifid(true), securifidButtonClicked='send-to-app'"> <i class="fas fa-mobile"></i>
                              {{ $t('labels.identify-via-app') }}
                        </button>
                      </div>
                    </div>
                  </v-guard>
                  <v-guard :permissions="['ticket-field-securifid-view-link-sms']" v-if="!securifidUser.registered">
                    <div class="d-flex my-auto mt-2 subheader pt-0" v-if="!securifidUser.error">
                      <div class="subheader-right mt-0 ">
                        <button type="button" class="btn btn-sm btn-light" v-on:click="sendSecurifidConfirm(), securifidButtonClicked='send-link-by-sms'"> <i class="fas fa-mobile"></i>
                              {{ $t('labels.submit-link-mobile') }}
                        </button>
                      </div>
                    </div>
                  </v-guard>
                </div>
                <div class="col-6 align-items-center d-flex">
                  <label v-if="securifid && ['submit-code-vocally', 'send-code-by-sms'].includes(securifidButtonClicked)"><h5 class="pb-2"><b style="padding: 20px; border: 1px solid #eaeaee; border-width: 5px;">{{ securifid.code }}</b></h5></label>
                  <label v-if="securifid && ['send-to-app', 'send-link-by-sms'].includes(securifidButtonClicked)"><h5 class="pb-2"><b style="padding: 20px; border: 1px solid #eaeaee; border-width: 5px;">Skickad</b></h5></label>
                </div>
              </div>
            </div>
          </div>
        </v-guard>
        <v-guard :permissions="['ticket-field-soft-delete-view']">
          <div class="widget mb-5">
            <div class="widget-header">
              <div class="widget-header-label">
                <h3>
                  {{ $t('labels.delete-ticket') }}
                </h3>
              </div>
            </div>
            <div class="widget-body" v-if="!isLoading">
              <div class="row">
                <div class="col-12">
                  <div class="d-flex my-auto subheader pt-0">
                    <div class="subheader-right mt-0 ">
                      <button @click="deleteCase" class="btn btn-danger">{{ $t('labels.delete-ticket') }}  <i class="fas fa-trash" style="margin-left: 10px;"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-guard>
      </div>
      <div class="col-lg-6 col-xl-3 order-lg-2 order-xl-1">
        <v-guard :permissions="['ticket-users-view']">
          <div class="widget">
            <div class="widget-header">
              <div class="widget-header-label">
                <h3>
                  {{ $t('labels.administrator') }} <info-box :field="'Ärendeformuläret - handläggare rubrik'" />
                  <small>{{ ticket.users.length }} {{ ticket.users.length === 1 ? this.$t('labels.administrator').toLowerCase() : this.$t('labels.administrators').toLowerCase() }}</small>
                </h3>
              </div>
              <div class="widget-header-toolbar" v-if="ticket.is_anonymized == 0">
                <v-guard :permissions="['ticket-user-add', 'ticket-user-add-only-own-user']">
                  <button
                      v-tooltip="this.$t('labels.add-administrator')"
                      type="button"
                      class="btn btn-btn btn-no-outline-white"
                      v-if="!addTicketUser"
                      v-on:click="toggleNewTicketUser"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                  <button type="button" class="btn btn-btn btn-no-outline-white" v-if="addTicketUser"
                          v-on:click="toggleNewTicketUser">
                    <i class="fas fa-times"></i>
                  </button>
                </v-guard>
              </div>
            </div>
            <div class="new-ticket-user bb-1 mb-4 pb-4" v-if="addTicketUser">
              <div class="form-row">
                <div class="form-group col-12">
                  <label>{{ $t('labels.user') }}</label>
                  <div v-if="ticketUser.id !== null">{{ ticketUser.user.label }}</div>
                  <div v-if="ticketUsersLoading">{{ $t('labels.loading') }}</div>
                  <v-select
                      v-if="ticketUser.id === null"
                      :options="users"
                      transition="none"
                      :filterable="true"
                      v-model="ticketUser.user"
                      :clearable="false"
                      label="label"
                  ><span slot="no-options">{{ $t('labels.no-options') }}</span>
                    <template slot="option" slot-scope="option">
                        <status-icon style="position: absolute; left: 3px;" :supporterStatusTag="option.supporter_status_tag"></status-icon>
                        {{ option.label }} ({{ option.owntickets_count }})
                    </template>
                  </v-select
                  >
                </div>
                <div class="form-group col-12">
                  <label>{{ $t('labels.role') }}</label>
                  <v-select
                      :options="ticketRoles"
                      transition="none"
                      :filterable="false"
                      v-model="ticketUser.role"
                      :clearable="false"
                      :label="this.$label()"
                  ><span slot="no-options">{{ $t('labels.no-options') }}</span></v-select
                  >
                </div>
              </div>
              <div class="text-right">
                <button type="button" @click="saveTicketUser" v-if="ticketUser.action === 'add'"
                        class="btn btn-success">{{ $t('labels.add') }}
                </button>
                <button type="button " @click="deleteTicketUser" v-if="ticketUser.action === 'update'"
                        class="btn btn-danger mr-3">
                  {{ $t('labels.delete') }}
                </button>
                <button type="button" @click="saveTicketUser" v-if="ticketUser.action === 'update'"
                        class="btn btn-success">{{ $t('labels.save') }}
                </button>
              </div>
            </div>
            <div class="widget-body pb-3" v-if="ticket.users.length !== 0 && !ticketUsersLoading">
              <div class="row mb-3" v-for="singleTicketUser in ticket.users" :key="singleTicketUser.id">
                <div class="col pr-1" style="flex: 0 0 45px;">
                  <div class="initials">
                    {{
                      singleTicketUser.user.name
                          .split(' ')
                          .map(function (item) {
                            return item[0];
                          })
                          .join('')
                          .substr(0, 2)
                    }}
                  </div>
                </div>
                <div class="col pl-4"> 
                  <div class="fw-400"><status-icon style="position: absolute; left:4px;" :member="singleTicketUser.user.member"></status-icon> {{ singleTicketUser.user.name }}</div>
                  <div class="mt-0 fs-12 text-muted">{{ $to(singleTicketUser.ticket_role,'label') }}</div>
                </div>

                <div class="col-2 pl-0" style="line-height: 42px;" v-if="ticket.is_anonymized == 0">
                  <div class="mt-0">
                    <v-guard :permissions="['ticket-user-edit']">
                      <button type="button" class="btn w-100 btn-white" v-on:click="editTicketUser(singleTicketUser)">
                        <i class="text-muted opacity-60 fas fa-ellipsis-v"></i>
                      </button>
                    </v-guard>
                    <v-guard :permissions="['ticket-user-edit-only-own-user']">
                      <button
                          type="button"
                          class="btn w-100 btn-white"
                          v-on:click="editTicketUser(singleTicketUser)"
                          v-if="singleTicketUser.user.id == user.user.id"
                      >
                        <i class="text-muted opacity-60 fas fa-ellipsis-v"></i>
                      </button>
                    </v-guard>
                  </div>
                </div>
              </div>
            </div>

            <div class="widget-body" v-if="ticket.users.length === 0 || ticketUsersLoading">
              <div class="empty-placeholder">
                <i class="fas fa-user-times"></i>
                {{ $t('labels.no-connected-administrators') }}
              </div>
            </div>
          </div>
        </v-guard>

        <v-guard :permissions="['ticket-todo-view']">
          <div class="widget comments">
            <div class="widget-header">
              <div class="widget-header-label">
                <h3>
                  {{ $t('labels.todo') }} <info-box :field="'Ärendeformuläret - Schemaläggning'" />
                  <small
                  >{{ todos.length }} {{ $t('labels.reminders').toLowerCase() }}</small
                  >
                </h3>
              </div>
                <div class="widget-header-toolbar" v-if="ticket.is_anonymized !== 1">
                  <button type="button" class="btn btn-btn btn-no-outline-white" v-on:click="hideNewTodo"
                          v-if="addTodo">
                    <i class="fas fa-times"></i>
                  </button>
                  <button
                      v-tooltip="this.$t('labels.add-todo')"
                      type="button"
                      class="btn btn-btn btn-no-outline-white"
                      v-on:click="addNewTodo"
                      v-if="!addTodo"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
            </div>
            <div class="new-comment bb-1 mb-4 pb-4" v-if="addTodo">
              <div class="form-group">
                <label for="edit-todo_text">{{ $t('labels.comment') }}</label>
                <textarea-autosize
                    placeholder=""
                    ref="someName"
                    :min-height="30"
                    :max-height="650"
                    class="form-control"
                    v-model="todo.comment"
                    id="edit-todo_text"
                ></textarea-autosize>
              </div>
              <div class="form-row">
                <div class="form-group col-12">
                  <label>{{ $t('labels.remind-to') }}</label>
                  <v-select
                      :options="todoCategories"
                      transition="none"
                      :filterable="false"
                      v-model="todo.todo_category"
                      :clearable="false"
                      :label="this.$label()"
                  >
                    <span slot="no-options">{{ $t('labels.no-options') }}</span>
                  </v-select>
                </div>
                <div class="form-group col-12">
                  <label>{{ $t('labels.remind-at') }}</label>
                  <input type="datetime-local" v-model="todo.due_at_datetime_local" class="form-control"/>
                </div>
                <div class="form-group col-12">
                  <v-guard :permissions="['ticket-todo-assign-other']">
                  <label>{{ $t('labels.remind-others') }}</label>
                  <div v-if="ticketUser.id !== null">{{ ticketUser.user.label }}</div>
                  <div v-if="ticketUsersLoading">{{ $t('labels.loading') }}</div>
                  <v-select
                      :options="users"
                      transition="none"
                      :filterable="true"
                      v-model="todoUser"
                      :clearable="false"
                      label="label"
                  ><span slot="no-options">{{ $t('labels.no-options') }}</span></v-select>
                  </v-guard>
                </div>
              </div>

              <div class="row">
                <div class="col-3">
                    <button type="button" @click="deleteTodo(todo)" v-if="todo.id !== null"
                            class="btn btn-outline-danger">
                      {{ $t('labels.delete') }}
                    </button>
                </div>
                <div class="col-9 text-right">
                  <button type="button" @click="saveTodo" class="btn btn-success">{{ $t('labels.add-reminder') }}</button>
                </div>
              </div>
            </div>
            <div class="widget-body">
              <div class="empty-placeholder" v-if="todos.length === 0">
                <i class="fas fa-calendar-times"></i>
                {{ $t('labels.no-reminders-registered') }}.
              </div>
              <div class="row bb-1 pb-4 mb-4" v-for="todo in todos" :key="todo.id">
                 <div class="col-12">
                  <div class="row">
                    <div class="col-10 pt-2">
                      <div
                          class="label mr-3 label-xs"
                          :style="{
													color: todo.todo_category.action.parent_action.color,
													backgroundColor: todo.todo_category.action.parent_action.color + '1A',
												}"
                          v-if="todo.todo_category.action && todo.todo_category.action.parent"
                      >
                        {{ $to(todo.todo_category,'label') }}
                      </div>
                      <div
                          class="label label-xs"
                          :style="{ color: todo.todo_category.action.color, backgroundColor: todo.todo_category.action.color + '0A' }"
                          v-if="todo.todo_category.action"
                      >
                        {{ $to(todo.todo_category.action,'label') }}
                      </div>
                    </div>
                    <div class="col-2 text-right" style="line-height: 42px;" v-if="ticket.is_anonymized == 0 && ($store.getters.permissions.includes('ticket-todo-edit-other') || todo.user_id == user.user.id)">
                      <div class="mt-0">
                          <button type="button" class="btn btn-white" @click="editTodo(todo)"
                                  style="width: 32px;">
                            <i class="text-muted opacity-60 fas fa-ellipsis-v"></i>
                          </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="mb-3 mt-2">{{ todo.comment }}</div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-6 mb-3 list">
                      <div class="list-item">
                        <label>{{ $t('labels.remind') }}</label>
                        <span>{{ todo.user.name }}</span>
                      </div>
                    </div>
                    <div class="col-6 list">
                      <div class="list-item">
                        <label>{{ $t('labels.remind-at') }}</label>
                        <span v-if="todo.due_at"> {{ todo.due_at_formatted }}</span>
                      </div>
                    </div>
                    <div class="col-6 list">
                      <div class="list-item">
                        <label>{{ $t('labels.created-at') }}</label>
                        <span> {{ todo.created_at_formatted }}</span>
                      </div>
                    </div>
                    <div class="col-6 list">
                      <div class="list-item">
                        <label>{{ $t('labels.updated-at') }}</label>
                        <span> {{ $to(todo,'updated_at_formatted') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-guard>

        <v-guard :permissions="['ticket-timeline-view']">
          <div class="widget comments">
            <div class="widget-header">
              <div class="widget-header-label">
                <h3>
                  <i class="fas fa-circle-notch fa-spin" v-show="isLoading"></i> {{ $t('labels.timeline') }}
                  <small>{{ ticket.timeline.length }} {{
                      ticket.timeline.length === 1 ? this.$t('labels.changing').toLowerCase() : this.$t('labels.changes').toLowerCase()
                    }}</small>
                </h3>
              </div>
            </div>
            <div class="widget-body">
              <div class="empty-placeholder" v-if="ticket.timeline.length === 0">
                <i class="fas fa-stream"></i>
                {{ $t('labels.no-events-on-the-timeline-yet') }}
              </div>
              <div class="timeline-wrapper">
                <div v-if="ticket.timeline.length !== 0">
                  <div class="timeline-item" v-for="timeline in limitedTimeline" :key="timeline.id">
                    <div class="timeline-item-section">
                      <div class="timeline-item-section-border">
                        <div
                            class="timeline-item-section-icon"
                            :class="timeline.label"
                            :style="{ color: timeline.color, backgroundColor: timeline.color + '1A' }"
                        >
                          <i class="fas" :style="{ color: timeline.color + ' !important' }" :class="timeline.icon"></i>
                        </div>
                      </div>
                      <span class="timeline-item-datetime">{{ timeline.time }}</span>
                    </div>

                    <div class="timeline-item-text" v-html="locale ? timeline.text : timeline.text_en"></div>

                    <div class="timeline-item-info">
                      {{ timeline.user }}
                    </div>
                  </div>
                </div>
                <div class="text-center pt-4" v-if="ticket.timeline.length > 50000">
                  <router-link class="btn btn-outline-grey" :to="{ name: 'ticket.history', params: { id: ticket.id } }"
                  >{{ $t('labels.show-more') }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </v-guard>
      </div>
    </div>
  </div>
</template>

<script>
import BojAPI from '@/api/boj';
import InfoBox from '@/components/InfoBox';
import StatusIcon from '@/components/member/StatusIcon';
import Confirm from '@/components/ticket/Confirm';
import DueActions from '@/components/ticket/DueActions.vue';
import TextareaField from '@/components/ticket/fields/TextareaField';
import { i18n } from '@/plugins/i18n';
import _ from 'lodash';
import DynamicSelect from 'vue-dynamic-select';

export default {
  name: 'ticket-overview',
  components: {
    DueActions,
    DynamicSelect,
    TextareaField,
    InfoBox,
    i18n,
    StatusIcon,
    Confirm,
  },
  props: {
    id: Number,
    ticket: Object,
  },
  watch: {
    'comment.parentAction': function (newVal, oldVal) {
      if (newVal !== null && oldVal !== null && newVal !== oldVal) {
        this.comment.childAction = null;
        this.comment.time_estimate = 0;
      }
    },

    'comment.childAction': function (newVal, oldVal) {
      if (this.comment.initial_values === true) {
        this.comment.initial_values = false;
      } else {
        if (newVal !== null && newVal !== oldVal) {
          this.comment.time_estimate = newVal.time_estimate;
        }
      }
    },
  },
  data() {
    return {
      addTicketUser: false,
      addComment: false,
      addTodo: false,
      todoUser: null,
      todoCategories: [],
      commentOptions: [],
      mode: 'view',
      ticketUsersLoading: true,
      isLoading: true,
      securifid: null,
      securifidButtonClicked: null,
      securifidUser: null,
      ticketUser: {
        id: null,
        user: {
          id: null,
          label: null,
        },
        role: {
          id: null,
          label: null,
        },
        action: 'add',
      },
      ticketRoles: {},
      users: {},
      comment: {
        initial_values: false,
        id: null,
        time_estimate: 0,
        comment: '',
        parentAction: null,
        childAction: null,
      },
      todo: {
        initial_values: false,
        id: null,
        comment: '',
        todoCategory: null,
      },
      dialog: false,
    };
  },
  computed: {

    locale() {
      return i18n.locale == 'sv' ? true : false;
    },
    user() {
      return this.$store.getters.user;
    },
    totalTimeComments() {
      return this.timeConvert(
          _.sumBy(this.ticket.comments, function (o) {
            return o.time_estimate ? Number.parseInt(o.time_estimate) : 0;
          }),
      );
    },
    assignedUsersLabels() {
      let labels = [];
      this.ticket.users.forEach(function (value, index) {
        if (!labels.includes(value.ticket_role.label)) {
          labels.push(value.ticket_role.label);
        }
      });
      return labels;
    },
    limitedTimeline() {
      return this.ticket.timeline;
    },
    dueActions() {
      return this.ticket.comments.filter((item) => item.is_due === 1);
    },

    actions() {
      return this.ticket.comments.filter((item) => item.is_due === 0);
    },

    todos() {
      return this.ticket.todos.filter((item) => item.id > 0);
    },

    parentActions() {
      const actions = this.commentOptions;
      var resp = actions.filter((item) => item.parent === null);

      return resp;
    },
    childActions() {
      if (this.comment.parentAction !== null) {
        let parentAction = this.comment.parentAction.id;
        const actions = this.commentOptions;
        const filteredActions = actions.filter((item) => item.parent === parentAction);
        const sortedFilteredActions = _.sortBy(filteredActions, 'label');

        return sortedFilteredActions
      } else {
        return [];
      }
    },

    childAction() {
      return this.ticket.comments.filter((item) => item.id === this.comment.childAction.id);
    },
  },

  mounted() {
    this.getTicket();
    this.getActions();
    this.getTodoCategories();
    this.getUsersAndTicketRoles();
  },
  methods: {

    /**
     * Display Error Message
     */
    showError(value) {
      this.$notify({
        group: 'foo',
        text: value,
        type: 'warning',
        duration: 3000,
      });
    },

    /**
     * Display Confirmation Message
     */
    showConfirmation(value) {
      this.$notify({
        group: 'foo',
        text: value,
        type: 'success',
        duration: 3000,
      });
    },

    timeConvert(n) {
      return n;
      /*var num = n;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return rhours + ' timer och ' + rminutes + ' minuter.';*/
    },

    editTicketUser(user) {
      this.addTicketUser = true;
      this.ticketUser.id = user.id;
      this.ticketUser.action = 'update';
      this.ticketUser.user.id = user.user.id;
      this.ticketUser.user.label = user.user.name;
      this.ticketUser.role.id = user.ticket_role.id;
      this.ticketUser.role.label = user.ticket_role.label;
    },

    deleteComment(comment) {
      BojAPI.removeTicketComment(this.ticket, comment)
          .then(() => {
            this.hideNewComment();
            this.getTicket();
            this.$notify({
              group: 'foo',
              text: this.$t('labels.event-deleted'),
              type: 'success',
              duration: 3000,
            });
          })
          .catch((error) => {
            this.$notify({
              group: 'foo',
              text: this.$t('labels.no-permission-deleting-event'),
              type: 'warning',
              duration: 3000,
            });
          });
    },

    deleteTodo(todo) {
      BojAPI.removeTodo(todo)
          .then(() => {
            this.hideNewTodo();
            this.getTicket();
            this.$notify({
              group: 'foo',
              text: this.$t('labels.event-deleted'),
              type: 'success',
              duration: 3000,
            });
          })
          .catch((error) => {
            this.$notify({
              group: 'foo',
              text: this.$t('labels.no-permission-deleting-event'),
              type: 'warning',
              duration: 3000,
            });
          });
    },

    editComment(comment) {
      this.addComment = true;
      this.comment = comment;
      this.$set(this.comment, 'parentAction', comment.action.parent_action);
      this.$set(this.comment, 'childAction', comment.action);
      this.$set(this.comment, 'initial_values', true);
    },

    editTodo(todo) {
      this.addTodo = true;
      this.todo = todo;

      var member = this.users.filter((item) => item.id == todo.user.id);
      this.todoUser = member.length > 0 ? member[0] : this.user.user;
      this.$set(this.todo, 'initial_values', true);
    },

    toggleNewTicketUser() {
      this.addTicketUser = !this.addTicketUser;
      this.resetTicketUser();
    },

    resetTicketUser() {
      this.ticketUser = {
        id: null,
        user: {
          id: null,
          label: null,
        },
        role: {
          id: null,
          label: null,
        },
        action: 'add',
      };
    },

    deleteTicketUser() {
      this.ticketUser.action = 'delete';
      this.saveTicketUser();
    },
    deleteCase() {
      if (confirm('Är du säker?')) {
        BojAPI.deleteTicket(this.ticket.id)
          .then((response) => {
            let self = this;
            this.$notify({
              group: 'foo',
              text: this.$t('labels.ticket-deleted'),
              type: 'success',
              duration: 3000,
            });
            setTimeout(function () {
              self.$router.push({name: 'tickets'});
            }, 3000);
          })
          .catch((error) => {
            this.$notify({
              group: 'foo',
              text: this.$t('labels.no-permission-deleting-case'),
              type: 'warning',
              duration: 3000,
            });
          });
      }
    },
    saveTicketUser() {
      this.ticketUsersLoading = true;
      this.addTicketUser = false;
      BojAPI.saveTicketUser(this.ticket.id, this.ticketUser)
          .then(() => {
            this.resetTicketUser();
            this.getTicket();
            this.showConfirmation(this.$t('labels.new-administrator-saved'));
          })
          .catch((error) => {
            this.ticketUsersLoading = false;
            this.showError(this.$t('labels.no-permission'));
          });
    },

    saveNewComment() {
      if (this.comment.parentAction == null || this.comment.childAction == null) {
        this.showError(this.$t('labels.missing-forum-event'));
      } else {
        BojAPI.saveNewTicketComment(this.ticket, this.comment)
            .then(() => {
              this.hideNewComment();
              this.getTicket();
              this.showConfirmation(this.$t('labels.new-event-saved'));
            })
            .catch((error) => {
              this.showError(this.$t('labels.no-permission'));
            });
      }
    },

    saveTodo() {
        var userId = this.todoUser.id ? this.todoUser.id : this.user.user.id;
        if(this.todo.id) {
            BojAPI.updateTodo(this.ticket, this.todo, userId)
            .then(() => {
              this.hideNewTodo();
              this.getTicket();
              this.showConfirmation(this.$t('labels.new-event-saved'));
            })
            .catch((error) => {
              this.showError(this.$t('labels.no-permission'));
            });
        } else {
         BojAPI.saveNewTodo(this.ticket, this.todo, userId)
            .then(() => {
              this.hideNewTodo();
              this.getTicket();
              this.showConfirmation(this.$t('labels.new-event-saved'));
            })
            .catch((error) => {
              this.showError(this.$t('labels.no-permission'));
            });

      }


    },
    /**
     * Add comment
     *
     * If ticket is closed and user has permission, ask to reopen.
     *
     * If user confirms, reopen ticket and show form.
     */
    addNewComment() {
      if (this.ticket.ticketstatus_id === 5) {
        if (this.$store.getters.permissions.includes('ticket-reopen-ticket')) {
          this.$confirm({
            message: this.$t('labels.ticket-reopen-ticket'),
            button: {
              no: 'Nej',
              yes: 'Ja',
            },
            callback: (confirm) => {
              if (confirm) {
                BojAPI.updateTicketField(this.ticket, 'ticketstatus_id', 4)
                    .then(() => {
                      this.getTicket();
                      this.showConfirmation(this.$t('labels.status-set-to-ongoing'));
                    })
                    .catch((error) => {
                      this.showError(this.$t('labels.no-permission'));
                    });
                this.addComment = true;
              } else {
                this.addComment = true;
              }
            },
          });
        } else {
          this.showError(this.$t('labels.no-permission-reopen'));
        }
      } else {
        this.addComment = true;
      }
    },
    addNewTodo() {
        this.todoUser = '';
        this.addTodo = true;
    },

    /**
     *
     */
    hideNewComment() {
      this.addComment = false;
      this.comment = {
        comment: '',
        id: null,
        parentAction: null,
        childAction: null,
      };
    },

    hideNewTodo() {
      this.addTodo = false;
      this.todo = {
        comment: '',
        id: null,
      };
    },

    /**
     *
     */
    getActions() {
      BojAPI.getActions().then((response) => {
        this.commentOptions = response.data.data;
      });
    },

    getTodoCategories() {
      BojAPI.getTodoCategories().then((response) => {
        this.todoCategories = response.data;
      });
    },

    /**
     *
     */
    getUsersAndTicketRoles() {
      this.ticketUsersLoading = true;
      BojAPI.getUsersAndTicketRoles().then((response) => {
        this.ticketRoles = response.data.roles;
        this.users = response.data.users;
        this.ticketUsersLoading = false;
      });
    },

    getCodeFromSecurifid(sendToMobile = false, useOrganizationSmsTextOverride = false) {
      this.securifid = null;
        BojAPI.identificationsSecurifid(this.ticket.id, this.user.user.member.id, sendToMobile, useOrganizationSmsTextOverride).then((response) => {
          this.securifid = response.data;
          this.$notify({
                group: 'foo',
                text: this.$t('labels.code-updated'),
                type: 'success',
                duration: 3000,
              });
        })
        .catch((error) => {
            this.showError(error);
        });
    },
    getUserFromSecurifid() {
      BojAPI.userSecurifid(this.ticket.id, this.user.user.member.id).then((response) => {
        this.securifidUser = response.data;
      })
      .catch((error) => {
        this.showError(error.response.data.error);
        this.securifidUser = {};
        this.securifidUser.error = error.response.data.error;
      });
    },
    sendSecurifidConfirm() {
      this.$confirm({
        message: this.$t('labels.securifid-message-confirm-message'),
        button: {
          yes: this.$t('labels.yes-send'),
          no: this.$t('labels.no-cancel'),
        },
        callback: (confirm) => {
          if(confirm) {
            this.getCodeFromSecurifid(true, true)
          }
        },
      }); 
    },  
    
    /**
     *
     */
    saveTicket() {
      this.mode = 'view';
      BojAPI.updateTicket(this.ticket)
          .then(() => {
            this.showConfirmation(this.$t('labels.changes-saved'));
          })
          .catch((error) => {
            this.showError(this.$t('labels.no-permission'));
          });
    },

    /**
     *
     */
    toggleMode() {
      if (this.mode === 'view') {
        this.mode = 'edit';
      } else {
        this.mode = 'view';
      }
    },
    /**
     *
     */
    getTicket: function () {
      this.$emit('get-ticket');
      this.isLoading = false;
      this.ticketUsersLoading = false;
    },
  },
};
</script>

<style scoped>
.mobile {
  font-size: 15px;
  font-weight: 500;
}
</style>
