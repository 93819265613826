<template>
  <div class="form-group row">
    <label class="col-sm-4 col-form-label" v-if="label.length !== 0">
      {{ label }}<info-box :field="infoBox" />
    </label>
    <div class="position-relative" :class="type !== 'email' && type !== 'tel' ? 'col-sm-8' : 'col-sm-6 pr-0'">
      <div class="pl-4 pt-2">
        <input
            value="1"
            v-if="editable"
            :type="type"
            v-model="model[field]"
            @change="updateField(model, field)"
            class="form-check-input"
            :id="'input' + field"
            :disabled="disabled"
            @click="clickEvent"
        />
        <label v-if="editable" class="form-check-label" :for="'input' + field">
          {{ text }}
        </label>
        <small class="text-muted mt-1" v-if="help.length !== 0">{{ help }}</small>
        <i v-if="tooltip" class="pl-2 far fa-question-circle" v-tooltip="{ content: 'Automatisk risk är aktiverat' }"></i>
        <slot></slot>
      </div>
      <div class="" v-if="!editable">
        <i v-if="!model[field]" class="fas fa-times-circle text-danger"></i>
        <i v-if="model[field]" class="fas fa-check-circle text-success"></i>
      </div>
    </div>
  </div>
</template>

<script>
import BojAPI from '@/api/boj';
import InfoBox from '@/components/InfoBox';

export default {
  name: 'loader',
  props: {
    api: String,
    editable: {
      type: Boolean,
      default: true,
    },
    text: String,
    type: {
      type: String,
      default: 'checkbox',
    },

    help: {
      type: String,
      default: '',
    },
    model: Object,
    field: String,
    infoBox: String,
    label: String,
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: Boolean,
      default: false
    },
    save: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    InfoBox
  },
  methods: {
    clickEvent(e) {
      this.$emit('click', e);
    },

    updateField: function (item, field) {
      if (this.save) {
        var value = item[field];

        BojAPI[this.api](this.model, field, value)
            .then(() => {
              this.$emit('fieldSaved');
              this.$emit('fieldSavedWithData', field);

              this.$notify({
                group: 'foo',
                text: this.$t('labels.changes-saved'),
                type: 'success',
                duration: 3000,
              });
            })
            .catch((error) => {
              this.$notify({
                group: 'foo',
                text: this.$t('labels.no-permission'),
                type: 'warning',
                duration: 3000,
              });
            });
      } else {
        this.$emit('changed', event.target.value);
      }
    },
  },
};
</script>
