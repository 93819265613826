<template>
  <div class="survey">
    <div class="row">

       <div class="col-lg-12">
        <div class="widget">

          <div class="widget-header">
            <div class="widget-header-label">
              <h3>Nedlagd tid</h3>
            </div>
          </div>

          <div class="widget-body pr-0 pl-3">
              <div class="container-fluid">


                <div class="row flex-column">
                  <div v-for="type in timeTypesOptions" :key="type.id" class="col-4">
                    <number-field
                      api="updateTestimonyTime"
                      :editable="true"
                      :model.sync="timeModel"
                      :timeType="type"
                      :value="getTimeSpent(type)"
                      field="time"
                      db_field="time"
                      placeholder="Ange tid i minuter"
                      type="number"
                      :label="type.label"
                      :submitWithButton="false"
                      :removable="false"
                      @fieldChanged="refreshTime"
                      :infoBox='"Domstol - Nedlagd tid - " + type.label'
                    ></number-field>
                  </div>
                </div>

              </div>
            </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import BojAPI from '@/api/boj';
import NumberField from '@/components/witness/fields/NumberField';
import { i18n } from '@/plugins/i18n';
import _ from 'lodash';

export default {
  name: 'witness-time-overview',
  components: {
    NumberField,
    i18n
  },
  props: {
    id: Number,
    testimonyTime: Object,
    testimonyFieldOptions: Object,
    isLoadingOptions: Boolean
  },
  data() {
    return {
      timeModel: null,
      timeTypesOptions: []
    }
  },
  watch: {
    testimonyFieldOptions: {
      handler() {
        this.setStoreData();
      },
      deep: true
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.checkIfAllTimeIsEmpty()) {
       this.$confirm(
        {
        message: 'Ingen tid är registrerad. Den här tidrapporten kommer att raderas.',
        button: {
          no: 'Registrera tid',
          yes: 'OK',
        },
        callback: confirm => {
          if (confirm) {
            BojAPI.removeTestimonyTime(this.timeModel.id)
            .then(() => {
              this.$root.loading = true;

              setTimeout(function () {
                next()
              }, 3000);

              this.$notify({
                group: 'foo',
                text: 'Tiden raderad',
                type: 'success',
                duration: 3000,
              });

            })
            .catch((error) => {
              this.$notify({
                group: 'foo',
                text: 'Något gick fel',
                type: 'warning',
                duration: 3000,
              });
            });
          }
        }
      })
    } else {
      next();
    }

  },
  mounted() {
    this.setData(this.testimonyTime);
  },
  methods: {
    setStoreData() {
      this.timeTypesOptions = this.testimonyFieldOptions.testimony_time_types ?? [];
    },
    setData(payload) {
      this.timeModel = payload;
    },
    getTimeSpent(timeType) {
      const timeSpent = this.timeModel.testimony_time_types.find((type) => type.id == timeType.id);

      if (timeSpent) {
        return timeSpent.pivot.time
      }

      return null
    },
    checkIfAllTimeIsEmpty() {
      return this.timeModel.testimony_time_types.every((type) => type.pivot.time == 0 || type.pivot.time == null || type.pivot.time == '')
    },
    refreshTime() {
      return BojAPI.getTestimonyTime(this.timeModel.id)
      .then((response) => {
        this.setData(response.data.data);
      })
      .catch((error) => {
        let self = this;

        if (error.response.status == 404) {
          setTimeout(function () {
              self.$router.push({name: 'cases.times'});
            }, 2000);
          return
        }

        this.$notify({
          group: 'foo',
          text: 'Något gick fel när tiderna skulle hämtas',
          type: 'warning',
          duration: 3000,
        });
      })
    },
  }
};
</script>

<style scoped>
.mobile {
  font-size: 15px;
  font-weight: 500;
}

.testimony-options-loading {
   display: flex;
   flex-direction: column;
}

.testimony-options-loading .line {
  float: left;
  height: 10px;
  margin-top: 12px;
  width: 250px;
  border-radius: 7px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  animation: shine-lines 1.6s infinite linear;
}
.testimony-options-loading .line {
  margin-top: 20px;
}
 .testimony-options-loading .line ~ .line {
  background-color: #ddd;
}
 @keyframes shine-lines {
   0% {
     background-position: -100px;
  }
   40%, 100% {
     background-position: 250px;
  }
}
 /* @keyframes shine-avatar {
   0% {
     background-position: -32px;
  }
   40%, 100% {
     background-position: 208px;
  }
} */

</style>
