<template>
  <div class="survey">
    <div class="row">

      <!--

        "col-lg-6 col-xl-3 order-lg-2 order-xl-1"
        col-lg-12 col-xl-5 order-lg-1 order-xl-1"
        "col-lg-6 col-xl-4 order-lg-2 order-xl-1"

       -->
       <div class="col-lg-12">
        <div class="widget">
          <div class="widget-footer with-tabs bb-1">
            <ul class="nav nav-tabs nav-tabs-line" role="tablist">
              <v-guard :permissions="['member-section-basics']">
                <li :class="{ 'current-section': showDetailSection === 1 }" class="nav-item section">
                  <div @click="setShowDetailSection(1)" class="nav-link">Personer</div>
                </li>
              </v-guard>
              <!-- <v-guard :permissions="['court-case-delete']">
                <li :class="{ 'current-section': showDetailSection === 2 }" class="nav-item section ml-auto mr-0">
                  <div class="d-flex justify-content-between">
                    <div
                      @click="$modal.show('Domstol - Radera mål-knapp'); setShowDetailSection(2);"
                      class="nav-link">
                        {{ $t('labels.delete-case') }}
                    </div>
                    <info-box style="display: inline-flex;" :field="'Domstol - Radera mål-knapp'" />
                  </div>
                </li>
              </v-guard> -->
            </ul>
          </div>
          
          <div class="widget-body pr-0 pl-3">
              <div class="container-fluid">
                <div class="row" v-show="showDetailSection == 1">
                  <div class="col-4 br-1 mb-5 pb-1 bb-1" v-for="p,i in testimony.persons" :key="p.id">
                    <div class="d-flex flex-wrap justify-content-between align-items-center bb-1 pb-2 mb-3" :class="{'interview-bg-witness': p.consent_interview_d == true }">
                      <h5 style="font-size: 1rem; margin">Person #{{ i + 1 }}</h5>
        
                      <h5  style="font-size: 1rem;"><status-icon :member="testimony.users.find(u => u.id == p.user_id).member"></status-icon> H: {{ testimony.users.find(u => u.id == p.user_id).name }}</h5>
                      <div class="justify-content-end">
                      <button
                            v-if="testimony.is_editable"
                            @click="showConsentData(p.id)"
                            class="btn btn-outline-success btn-sm"
                            style="margin-top: -6px;"
                          >
                          <i class="fa fa-user-tag"></i>
                        </button>
                      <button
                          v-if="testimony.is_editable"
                          @click="removePerson(p.id)"
                          class="btn btn-outline-danger btn-sm ml-2"
                          style="margin-top: -6px;"
                        >
                        <i class="fas fa-trash"></i>
                      </button>
                      </div>
                    </div>
                    <!-- :editable="ticket.is_anonymized !== 1 && $store.getters.permissions.includes('ticket-section-person-edit')" -->
                    <select-field
                      api="updateTestimonyPersonField"
                      :clearable="false"
                      :model="p"
                      :multiple="false"
                      :options="testimonyFieldOptions.gender"
                      db_field="gender_id"
                      field="gender"
                      :infoBox="'Ärendeformuläret - flik Om stödsökanden, Kön'"
                      :label="$t('labels.gender')"
                      :optionLabel="$label()"
                      @fieldSaved="getTestimony"
                      :editable="testimony.is_editable"
                    ></select-field>
                    <select-field
                      api="updateTestimonyPersonField"
                      :clearable="false"
                      :model="p"
                      :multiple="false"
                      :options="testimonyFieldOptions.ages"
                      db_field="ages_id"
                      field="ages"
                      :infoBox="'Ärendeformuläret - flik Om stödsökanden, Åldersgrupp'"
                      :label="$t('labels.age')"
                      :optionLabel="$label()"
                      @fieldSaved="getTestimony"
                      :editable="testimony.is_editable"
                    ></select-field>
                    <select-field
                      api="updateTestimonyPersonField"
                      :clearable="false"
                      :model="p"
                      :multiple="false"
                      :options="filterTestimonyApplicant"
                      db_field="testimony_applicant_id"
                      field="testimony_applicant"
                      label="Stödsökande"
                      :infoBox="''"
                      @fieldSaved="getTestimony"
                      :editable="testimony.is_editable"
                    ></select-field>

                    <div class="testimony-options-loading" v-if="isLoadingOptions || isUpdating">
                      <div class="line"></div>
                      <div class="line"></div>
                      <div class="line"></div>
                    </div>

                    <multi-checkbox
                      v-if="!isLoadingOptions && !isUpdating"
                      api="updateTestimonyPersonField"
                      :multiple="true"
                      :options="testimonyFieldOptions.testimony_support"
                      :model="p"
                      label=""
                      :checkboxArray="p.testimony_support_ids"
                      db_field="testimony_support"
                      @fieldSaved="getTestimony"
                      :editable="testimony.is_editable"
                    ></multi-checkbox>

                    <v-guard :permissions="['testimony-support2-view']">
                    

                      <multi-checkbox
                        v-if="!isLoadingOptions && !isUpdating"
                        api="updateTestimonyPersonField"
                        :multiple="true"
                        :options="testimonyFieldOptions.testimony_support2"
                        :model="p"
                        label=""
                        :checkboxArray="p.testimony_support2_ids"
                        db_field="testimony_support2"
                        @fieldSaved="getTestimony"
                        :editable="testimony.is_editable"
                      ></multi-checkbox>
                    </v-guard>
                    
    
                    <v-guard v-if="showConsentSaveData[p.id] || p.consent_save_data" :permissions="['testimony-concent-view']">
                        <checkbox
                          v-if="!isLoadingOptions && !isUpdating"
                          api="updateTestimonyPersonField"
                          field="consent_save_data"
                          :model="p"
                          label="Samtycke spara personuppgifter"
                          @fieldSaved="getTestimony"
                          :editable="testimony.is_editable"
                          infoBox="Samtycke - spara personuppgifter D"
                        ></checkbox>
                      
                      <div v-if="p.consent_save_data && p.consent_save_data_user">
                        <div class="form-group"><label>{{ moment(p.consent_save_data_timestamp).format('YYYY-MM-DD') }}</label> <label>{{ p.consent_save_data_user.name }}</label></div>
                        <text-field
                          :editable="testimony.is_editable"
                          api="updateTestimonyPersonField"
                          :model="p" 
                          field="first_name"
                          :label="$t('labels.first-name')">
                        </text-field>
                        <text-field
                          :editable="testimony.is_editable"
                          api="updateTestimonyPersonField"
                          :model="p" 
                          field="last_name"
                          :label="$t('labels.last-name')">
                        </text-field>
                        <text-field
                            :editable="testimony.is_editable"
                            api="updateTestimonyPersonField"
                            :model="p" 
                            field="email"
                            :label="$t('labels.email')">
                        </text-field>
                        <text-field
                              :editable="testimony.is_editable"
                              api="updateTestimonyPersonField"
                              :model="p" 
                              field="mobile"
                              :label="$t('labels.mobile')">
                        </text-field>
                        <div class="form-group" v-if="p.interview_d_link && $store.getters.permissions.includes('ticket-access-all-tickets')">
                            <button v-if="!p.interview_d_completed_at" type="button" class="btn btn-light mr-3" v-on:click="sendCalendarInvite(p.id)"> <i class="fas fa-user-circle"></i>
                                  {{ $t('labels.send-calendar-invite') }}
                              </button> 

                            <a v-if="!p.interview_d_completed_at" :href="p.interview_d_link + '?interviewer_id=' + user.user.id" target="_blank">
                              <button class="btn btn-system-blue">
                                <i class="fas fa-user"></i> {{ $t('labels.start-interview') }}
                              </button>
                            </a>
                             
                            <label v-if="p.interview_d_completed_at" >{{ $t('labels.interview-completed-at') }} {{ moment(p.interview_d_completed_at).format('YYYY-MM-DD') }} </label>
                        </div>
                        
                      </div>
                        <div class="mt-5">
                        </div>
                      </v-guard>
                  </div>
                  <!-- <div class="row"> -->
                    <div class="col-4 text-center flex-column d-flex align-content-center justify-content-center flex-wrap pt-5 pb-5">
                    <!-- v-if="ticket.secondary_crimes.length !== 4 && n === 1"
                      v-if="!isAddingSecondaryCrime"


                    -->
                      <div>
                        <button v-if="!isAddingPerson && testimony.is_editable" class="btn btn-success btn-sm" @click="addPerson">
                          <i class="fas fa-plus mr-1"></i> Lägg till person
                        </button>
                        <button v-if="isAddingPerson" class="btn btn-success btn-sm">
                          <i class="fas fa-circle-notch fa-spin"></i>
                        </button>
                      </div>
                      <!-- <div class="text-muted">{{ $t('labels.place-additional-crime') }}</div> -->
                    </div>
                  <!-- </div> -->
                </div>
                <div class="row" v-show="showDetailSection == 2">
                  <div class="col-12">
                    <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.delete-case') }}</h5>
                    <button @click="deleteCase" class="btn btn-danger">{{ $t('labels.delete-case') }}</button>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import BojAPI from '@/api/boj';
import InfoBox from '@/components/InfoBox';
import SelectField from '@/components/ticket/fields/SelectField';
import TextField from '@/components/ticket/fields/TextField';
import MultiCheckbox from '@/components/ticket/fields/MultiCheckbox';
import Checkbox from '@/components/ticket/fields/Checkbox';
import moment from 'moment';
import { i18n } from '@/plugins/i18n';
import _ from 'lodash';
import StatusIcon from '@/components/member/StatusIcon';

export default {
  name: 'witness-overview',
  components: {
    InfoBox,
    SelectField,
    MultiCheckbox,
    i18n,
    Checkbox,
    TextField,
    StatusIcon,
  },
  props: {
    id: Number,
    testimony: Object,
    testimonyFieldOptions: Object,
    isAddingPerson: Boolean,
    isLoadingOptions: Boolean,
    isUpdating: Boolean,
    override: Boolean
  },
  watch: {},
  data() {
    return {
      showDetailSection: 1,
      mode: 'view',
      moment: moment,
      showConsentSaveData: {},
    };
  },
  computed: {
    locale() {
      return i18n.locale == 'sv' ? true : false;
    },
    user() {
      return this.$store.getters.user;
    },
    filterTestimonyApplicant() {
      if (Object.keys(this.testimonyFieldOptions).length === 0) {
        return
      }

      if (this.isMatchingType('Civilmål')) {
        return this.testimonyFieldOptions.testimony_applicants.filter(item => item.only_civil_cases == true)
      } else {
        return this.testimonyFieldOptions.testimony_applicants.filter(item => item.only_civil_cases == false)
      }
    },
  },
  methods: {
    showConsentData(i) {
      this.showConsentSaveData[i] = !this.showConsentSaveData[i];
      //Hacky way to redraw section :)
      this.setShowDetailSection(2);
      this.setShowDetailSection(1);
    },
    sendCalendarInvite(personId) {
      BojAPI.sendCalendarInvite(personId, 'witness', this.id).then((response) => {
        this.showConfirmation('Kalenderinbjudan skickad');
      })
        .catch((error) => {
          this.showError(error.response.data.error);
        });
    },
    /**
     * Toggles current form section
     *
     * @param section
     */
    setShowDetailSection(section) {
      this.showDetailSection = section;
    },

    isMatchingType(type) {
      let selectedType = this.testimonyFieldOptions.testimony_types.find(type => type.id == this.testimony.testimony_type_id);

      if (selectedType && selectedType.label == type) {
        return true;
      }

      return false
    },
    /**
     * Display Error Message
     */
    showError(value) {
      this.$notify({
        group: 'foo',
        text: value,
        type: 'warning',
        duration: 3000,
      });
    },

    /**
     * Display Confirmation Message
     */
    showConfirmation(value) {
      this.$notify({
        group: 'foo',
        text: value,
        type: 'success',
        duration: 3000,
      });
    },

    addPerson() {
      this.$emit('update:isAddingPerson', true)

      BojAPI.addTestimonyPerson(this.testimony.id)
        .then(() => {
          this.getTestimony();

          this.$notify({
            group: 'foo',
            text: 'Person tillagd',
            type: 'success',
            duration: 3000,
          });
          this.reRender = true;

        })
        .catch((error) => {
          this.getTestimony();

          this.$notify({
            group: 'foo',
            text: this.$t('labels.no-permission'),
            type: 'warning',
            duration: 3000,
          });
        });
    },

    removePerson(person_id) {

      this.$confirm({
        message: `Vill du radera personen?`,
        button: {
          no: 'Nej',
          yes: 'Ja',
        },
        callback: (confirm) => {
          if (confirm) {
            BojAPI.removeTestimonyPerson(person_id)
              .then(() => {
                this.getTestimony();
                this.$notify({
                  group: 'foo',
                  text: 'Person borttagen',
                  type: 'success',
                  duration: 3000,
                });

                this.reRender = true;
              })
              .catch((error) => {
                this.getTestimony();
                this.$notify({
                  group: 'foo',
                  text: this.$t('labels.no-permission'),
                  type: 'warning',
                  duration: 3000,
                });
              });
          }
        },
      });

    },

    /**
     * Deletes a member
     *
     * Redirect to members-page after 2 seconds,
     * allowing the notification to be shown.
     */
    deleteCase() {
      if (confirm('Är du säker?')) {
        this.$emit('update:override', true);

        BojAPI.deleteTestimony(this.testimony.id)
          .then((response) => {
            let self = this;
            this.$notify({
              group: 'foo',
              text: this.$t('labels.case-deleted'),
              type: 'success',
              duration: 3000,
            });
            setTimeout(function () {
              self.$router.push({name: 'cases'});
            }, 2000);
          })
          .catch((error) => {
            this.$notify({
              group: 'foo',
              text: this.$t('labels.no-permission-deleting-case'),
              type: 'warning',
              duration: 3000,
            });
          });
      }
    },

    /**
     *
     */
    toggleMode() {
      if (this.mode === 'view') {
        this.mode = 'edit';
      } else {
        this.mode = 'view';
      }
    },

    /**
     *
     */
    getTestimony: function () {
      this.$emit('get-witness');
    },
  },
};
</script>

<style scoped>
.mobile {
  font-size: 15px;
  font-weight: 500;
}

.testimony-options-loading {
   display: flex;
   flex-direction: column;
}

.testimony-options-loading .line {
  float: left;
  height: 10px;
  margin-top: 12px;
  width: 250px;
  border-radius: 7px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  animation: shine-lines 1.6s infinite linear;
}
.testimony-options-loading .line {
  margin-top: 20px;
}
 .testimony-options-loading .line ~ .line {
  background-color: #ddd;
}
 @keyframes shine-lines {
   0% {
     background-position: -100px;
  }
   40%, 100% {
     background-position: 250px;
  }
}
 /* @keyframes shine-avatar {
   0% {
     background-position: -32px;
  }
   40%, 100% {
     background-position: 208px;
  }
} */

</style>
