<template>
  <div class="form-group row">
    <label :for="'input' + field" class="col-sm-4 col-form-label" v-if="label.length !== 0">{{ label }}</label>

    <div class="col-12">
      <textarea-autosize
        v-if="editable"
        :placeholder="placeholder"
        ref="someName"
        :min-height="30"
        :max-height="650"
        @blur.native="updateTextField(model, field, $event)"
        class="form-control"
        :value="model[field]"
        :id="'ticket-edit-' + field"
      ></textarea-autosize>

      <div v-if="!editable && !disablePreview">{{ model[field] }}</div>

      <small class="text-muted mt-1" v-if="help.length !== 0">{{ help }}</small>
    </div>
  </div>


</template>

<script>
import BojAPI from '@/api/boj';

export default {
  name: 'loader',
  props: {
    api: String,
    editable: {
      type: Boolean,
      default: true,
    },
    help: {
      type: String,
      default: '',
    },
    disablePreview: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
    model: Object,
    field: String,
    label: String,
    save: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    updateTextField: function (item, field, event) {
      if (this.save) {
        if (item[field] != event.target.value) {
          this.model[field] = event.target.value;

          if (event.relatedTarget === null || !event.relatedTarget.classList.contains('close-edit')) {
            this.$emit('toggleMode');
          }

          BojAPI[this.api](this.model, field, event.target.value)
              .then(() => {
                this.$emit('fieldSaved', event.target.value);
                this.$emit('fieldSavedWithData', field);

                this.$notify({
                  group: 'foo',
                  text: this.$t('labels.changes-saved'),
                  type: 'success',
                  duration: 3000,
                });
              })
              .catch((error) => {
                this.$notify({
                  group: 'foo',
                  text: this.$t('labels.no-permission'),
                  type: 'warning',
                  duration: 3000,
                });
              });
        }
      } else {
        this.$emit('changed', event.target.value);
      }
    },
  },
};
</script>
