<template>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label" v-if="label.length !== 0">
      {{ label }}
    </label>
    <div class="position-relative" :class="type !== 'email' && type !== 'tel' ? 'col-sm-8' : 'col-sm-6 pr-0'">
      <div class="pl-4 pt-2" v-for="option in options" :key="option.id">
        <input
            class="form-check-input"
            type="checkbox"
            :id="model.id + '_' +option.id"
            :value="option.id"
            v-model="localValue"
            @change="updateField"
            :disabled="!editable"
          >
        <label class="form-check-label" :for="'input' + db_field">
          {{ option.label }}
        </label>
        <!-- <small class="text-muted mt-1" v-if="help.length !== 0">{{ help }}</small>
        <i v-if="tooltip" class="pl-2 far fa-question-circle" v-tooltip="{ content: 'Automatisk risk är aktiverat' }"></i> -->
        <slot></slot>
      </div>
      <!-- <div class="" v-if="!editable">
        <i v-if="!model[field]" class="fas fa-times-circle text-danger"></i>
        <i v-if="model[field]" class="fas fa-check-circle text-success"></i>
      </div> -->
    </div>
  </div>

</template>

<script>
import BojAPI from '@/api/boj';
import _ from 'lodash'

export default {
  name: 'loader',
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    useStore: {
      type: Boolean,
      default: false
    },
    dispatch: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'checkbox',
    },
    api: String,
    options: Array,
    model: Object,
    value: Array,
    db_field: String,
    label: String,
    checkboxArray: Array
  },
  data() {
    return {
      localValue: this.checkboxArray
    }
  },
  methods: {
    updateField(event) {

      BojAPI[this.api](this.model, this.db_field, this.localValue)
        .then(() => {
          this.$emit('fieldSaved');

          this.$notify({
            group: 'foo',
            text: this.$t('labels.changes-saved'),
            type: 'success',
            duration: 3000,
          });
        })
        .catch(() => {

          this.$notify({
            group: 'foo',
            text: this.$t('labels.no-permission'),
            type: 'warning',
            duration: 3000,
          });
        });
    },
  },
};
</script>
