<template>
  <div class="confirm">
    <div class="dialog">
      <div class="text">
      Du är på väg att sända sms till stödsökanden. Är du säker?
      </div>
      <div class="buttons">
        <button @click="closeDialog">Nej, avbryt.</button>
        <button @click="accept">Ja, sänd.</button>
      </div>
    </div>
  </div>
</template>
<script>
import InfoBox from '@/components/InfoBox';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

export default {
  data() {
    return {
      currentBody: '',
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        // The configuration of the editor.
      },
      signatures: [],
      signature: null,
      body: '',
      subject: '',
      getSortByOrder: true,
      getSortBy: '_score',
    };
  },
  created() {
    this.currentBody = this.body;
  },
  mounted() {
    this.$modal.show('confirm-modal');
    const body = document.querySelector('body');
    //body.style.overflow = 'hidden';
  },
  components: {
    InfoBox
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
    accept() {
      this.$emit('close');
      this.$emit('accept');
    },
  },
};
</script>
<style lang="scss" scoped>
.hide {
  display: none;
}
.confirm {
  position:absolute;
  display:flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  
  z-index: 1000;

  .dialog {
    box-sizing: border-box;
    background: white;
    border-radius: 2rem;
    border: 2px solid #dadada;

    .text {
      padding: 2rem;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      border-radius: 2rem;

      button {
        padding: 0.6rem 2rem;
        background: white;
        cursor: pointer;
        width:50%;
        color: #4d96da;
        font-weight: 500;
        &:first-child {
          border-top: 2px solid #dadada;
          border-left: none;
          border-bottom: none;
          border-right: none;
          border-radius:  0 0 0 2rem;
        }
        &:last-child {
          border-top: 2px solid #dadada;
          border-left: 2px solid #dadada;
          border-bottom: none;
          border-right: none;
          border-radius:  0 0 2rem 0;
        }
      }



    }
  }
}
</style>
