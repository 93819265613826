<template>
    <span><i :class="icon" :style="{'color': color}"></i></span>
</template>
<script>

export default {
    props: {
        supporterStatusTag: String,
        member: Object,
    },
    data() {
        return {

        };
    },
    created() {

    },
    mounted() {

    },
    components: {
        
    },
    computed: {
        color() {
            let voluneteerStatus = null;
            if(this.member) {
                voluneteerStatus = this.$store.getters.memberOptions.voluneteer_statuses.find(element => element.id == this.member.member_volunteer_status_id);
            } else {
                voluneteerStatus = this.$store.getters.memberOptions.voluneteer_statuses.find(element => element.tag == this.supporterStatusTag);
            }

            if (voluneteerStatus) {
                return voluneteerStatus.color;
            }

            return null;
        },
        icon() {
            let voluneteerStatus = null;

            if(this.member) {
                voluneteerStatus = this.$store.getters.memberOptions.voluneteer_statuses.find(element => element.id == this.member.member_volunteer_status_id);
            } else {
                voluneteerStatus = this.$store.getters.memberOptions.voluneteer_statuses.find(element => element.tag == this.supporterStatusTag);

            }
            if(voluneteerStatus) {
                return voluneteerStatus.icon;
            }

            return null;
        },
    },
    methods: {
      
    },
};
</script>
