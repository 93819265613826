<template>
  <div class="widget notification bg-success">
    <div class="widget-header light">
      <div class="widget-header-label">
        <h3 class="text-white opacity-60"><i class="far fa-bell mr-2"></i> {{ title }}</h3>
      </div>
    </div>
    <div class="widget-body">
      <div class="carousel slide" data-ride="carousel" data-interval="4000">
        <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="8000"
            img-width="1024"
            controls=""
            img-height="480"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd">
          <!-- Text slides with image -->
          <div class="carousel-item" v-for="action in msg" :key="action.id">
            <div class="single-notification">


              <div class="notification-title notification-title-big text-white">{{ action.action.label }} <span
                  class=" opacity-60"> om {{ action.due_in }} dagar</span></div>
              <div class="notification-subtitle text-white"><i class="far fa-calendar-alt mr-2"></i>
                {{ action.due_date_formatted }}
              </div>

            </div>
          </div>
        </b-carousel>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Notifications',
  props: {
    msg: Array,
    title: String
  },
  filters: {
    truncate: function (text, length, suffix) {
      return text.substring(0, length) + suffix;
    },
  },
  data() {
    return {
      slide: 0,
      sliding: null
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    }
  }
}
</script>
