<template>
  <div class="form-group row">
    <label :for="'input' + field" class="col-sm-4 col-form-label" v-if="label.length !== 0">
      <slot name="icon"></slot> {{ label }}<info-box :field="infoBox" />
    </label>
    <div v-if="rows === 1" class="position-relative"
         :class="type !== 'email' && type !== 'tel' ? 'col-sm-8' : 'col-sm-6 pr-0'">
      <input
          autocomplete="chrome-off"
          list="autocompleteOff"
          v-if="editable"
          :type="type"
          v-on:keyup.enter="$event.target.blur()"
          :value="model[field]"
          @keypress="($event) => onKeyPress ? onKeyPress($event) : true"
          @blur="updateTextField(model, field, $event)"
          class="form-control"
          :id="'input' + field"
      />
      <small class="text-muted mt-1" v-if="help.length !== 0">{{ help }}</small>
      <slot></slot>
      <div class="pt-2" v-if="!editable"
           v-html="model[field] !== '' ? model[field] : '<span class=\'text-muted\'>-</span>'"></div>
    </div>
    <div v-if="rows !== 1" class="col-12">
			<textarea
          v-if="editable && rows !== 1"
          :type="type"
          :rows="rows"
          :value="model[field]"
          @blur="updateTextField(model, field, $event)"
          class="form-control"
          :id="'ticket' + field"
      ></textarea>
      <div v-if="!editable">{{ model[field] }}</div>
    </div>

    <div class="col-sm-2" v-if="type == 'email'">
      <a tabindex="-1" :href="'mailto:' + model[field]" class="h-100 btn w-100 btn-light"><i
          class="mt-2 fas fa-envelope opacity-40"></i></a>
    </div>
    <div class="col-sm-2" v-if="type == 'tel'">
      <a tabindex="-1" :href="'tel:' + model[field]" class="h-100 btn w-100 btn-light"><i
          class="mt-2 fas fa-phone opacity-40"></i></a>
    </div>
  </div>
</template>

<script>
import BojAPI from '@/api/boj';
import InfoBox from '@/components/InfoBox';

export default {
  name: 'loader',
  props: {
    api: String,
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    rows: {
      type: Number,
      default: 1,
    },
    help: {
      type: String,
      default: '',
    },
    onKeyPress: Function,
    model: Object,
    field: String,
    infoBox: String,
    label: String,
    save: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    InfoBox
  },
  methods: {
    updateTextField: function (item, field, event) {
      if (this.save) {
        if (item[field] != event.target.value) {
          this.model[field] = event.target.value;

          if (field == 'related_tickets') {
            this.model[field] = this.model[field].replace(/[^0-9.,]/g, '');
          }

          BojAPI[this.api](this.model, field, event.target.value)
              .then(() => {
                this.$emit('fieldSaved', event.target.value);
                this.$emit('fieldSavedWithData', field);

                this.$notify({
                  group: 'foo',
                  text: this.$t('labels.changes-saved'),
                  type: 'success',
                  duration: 3000,
                });
              })
              .catch((error) => {
                console.log(error);
                let text = this.$t('labels.no-permission');
                if(error.response.data.errors && error.response.data.errors.email) {
                  text = this.$t('labels.email-error');
                } else if (error.response.data.errors && error.response.data.errors.mobile) {
                  text = this.$t('labels.mobile-error');
                } else if (error.response.data.message == 'invalid_phone_number') {
                  text = this.$t('labels.error-phone');
                } else if (error.response.data.message == 'current_age') {
                  text = this.$t('labels.error-age');
                } else if (error.response && error.response.data != undefined) {
                  text = 'Ärende ';

                  error.response.data.forEach((element,key,data) => {

                    if (key === data.length - 1){
                      text += element
                    } else {
                      text += element + ', ';
                    }

                  });

                  text += ' hittades inte! Kontrollera att det finns.'
                }

                this.$notify({
                  group: 'foo',
                  text: text,
                  type: 'warning',
                  duration: 5000,
                });
              });
        }
      } else {
        this.$emit('changed', event.target.value);
      }
    },
  },
};
</script>
